import React from "react"
import { Link } from "gatsby"



class CookieBar extends React.Component {

    cookie() {
        // set a key:value in local storage of the browser
        localStorage.setItem("cookie", "yes");
        // hide cookies bar
        document.querySelector("#cookie-bar").style.display = "none";
    }

    componentDidMount() {
        // check was the cookie policy clicked
        if( localStorage.getItem("cookie") === "yes" ){
            document.querySelector("#cookie-bar").style.display = "none";
            return false;
        }
        document.querySelector("#cookie-bar").style.display = "block";
    }
  
    render() {
        return (
        <div id="cookie-bar">
            <div className="cookie-inner">
                <div className="cookie-text">
                    <h2>Cookie Policy</h2>
                    <p>Cookies help us deliver our services. By visiting our website, you agree to our use of cookies</p>
                    <p>Click here to read our <Link to="/terms-and-conditions">terms & conditions</Link></p>
                </div>
                <div className="cookie-btn">
                    <div className="cookie-close-btn" onClick={this.cookie}>OK</div>
                </div>
            </div>
        </div>
        )
    }
}
  
export default CookieBar