import React from "react"
import logo from "../../assets/images/logo-light.acdb767d.svg"
import { Link } from "gatsby"

class Header extends React.Component {



  render() {
    return (
      <div>
        <div id="header" style={{ backgroundColor: this.props.bgcolor }}>    
        <div id="headerInner">      
          <Link to="/"><img src={logo} alt="ICON Worldwide logo" title="ICON Worldwide logo" /></Link>
          <div className="headerLinks">
            <Link to="/about" activeClassName="activeHeadeLink">About<div className="yellow-down-line"></div></Link>
            <Link to="/services" activeClassName="activeHeadeLink">Services<div className="yellow-down-line"></div></Link>
            <Link to="/works" activeClassName="activeHeadeLink" partiallyActive={true}>Work<div className="yellow-down-line"></div></Link>
            <Link to="/blog" activeClassName="activeHeadeLink" partiallyActive={true}>News<div className="yellow-down-line"></div></Link>
            <Link to="/contact-us"><div id="header-contact-btn">Contact</div></Link>
          </div>
        </div>
        </div>
      </div>
    )
  }
}

export default Header 