import React from "react"
import { Link } from "gatsby"

export default (props) => 
<div id="news-footer">
    <Link to="/blog">All News</Link>
    <div id="news-footer-next-prev">
        <Link to={`/blog/${props.previous}`}>Previous</Link>
        <Link to={`/blog/${props.next}`}>Next</Link>
    </div>
</div>