import React from "react"
import Header from "../header"
import CookieBar from "../cookieBar"
import ServiceMenu from "../services/servicesMenuHeader"

export default () => 

    <div id="news-header"> 
        <CookieBar/>
        <Header bgcolor="#292929"/>
        <div id="mobile-menu"><ServiceMenu bgcolor="#292929"/></div>
        <div id="news-header-title">Currently at <span>ICON</span> Worldwide</div>
    </div>